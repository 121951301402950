import React, { memo } from 'react';
import styles from './Time.module.scss';
import { InstanceType, IMessage } from '../../../service/type';

interface ITimeProps {
  nextMessage?: IMessage;
  currentMessage: IMessage;
  user: string;
}

// eslint-disable-next-line react/display-name
export const Time = memo((props: ITimeProps): JSX.Element | null => {
  const { nextMessage, currentMessage, user } = props;

  const position = currentMessage.user_created === user ? 'flex-end' : 'flex-start';

  const label = currentMessage.user_created === user ? '送信済' : 'AIアバター';

  const timeShow = new Date(currentMessage.created_at).toLocaleTimeString('ja-JP', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
  });

  if (
    currentMessage.instance_type === InstanceType.user_choices ||
    currentMessage.instance_type === InstanceType.end_of_scenario
  ) {
    return null;
  }

  if (nextMessage) {
    const nextDate = new Date(nextMessage.created_at).getTime();
    const currentDate = new Date(currentMessage.created_at).getTime();

    if (
      nextDate - currentDate < 5000 &&
      nextMessage.instance_type === currentMessage.instance_type &&
      currentMessage.user_created === nextMessage.user_created
    ) {
      return null;
    }
  }

  return (
    <div className={styles.container} style={{ justifyContent: position }}>
      <span>{label}</span>
      <span>{timeShow}</span>
    </div>
  );
});
