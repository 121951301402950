import { memo } from 'react';
import styles from './AvatarUtterance.module.scss';
import {
  IMessageOpenAIAnswer,
  IMessageIntentsAnswer,
  IMessageChatsAnswer,
  IMessageInitialMessage,
  IMessageGoogleFormInputMessage,
  IMessageGoogleFormThanksMessage,
  IMessageGoogleFormInfoMessage,
} from '../../../service/type';
import { UrlToLink } from '../../atoms/UrlToLink';

interface IAvatarUtteranceProps {
  data?:
    | IMessageOpenAIAnswer
    | IMessageIntentsAnswer
    | IMessageChatsAnswer
    | IMessageInitialMessage
    | IMessageGoogleFormInputMessage
    | IMessageGoogleFormThanksMessage
    | IMessageGoogleFormInfoMessage;
}

export const AvatarUtterance = memo(
  ({ data }: IAvatarUtteranceProps): JSX.Element => (
    <div className={styles.container}>
      <span>
        <UrlToLink inputText={data?.params.message} />
      </span>
    </div>
  ),
);

AvatarUtterance.displayName = 'AvatarUtterance';
