import styles from './ChatContainer.module.scss';
import { Header } from '../Header';
import { ChatMessages } from '../ChatMessages';
import { useChatContainer } from './ChatContainer.hooks';

export const ChatContainer = (): JSX.Element => {
  const { isShowQuitConfirm, showQuitConfirm, hideQuitConfirm, avatarSource } = useChatContainer();

  return (
    <div className={styles.container}>
      <Header onClick={showQuitConfirm} source={avatarSource} />
      <ChatMessages hideQuitConfirm={hideQuitConfirm} isShowQuitConfirm={isShowQuitConfirm} />
    </div>
  );
};
