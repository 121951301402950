/* eslint-disable max-lines-per-function */
import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import styles from './ChatMessages.module.scss';
import { Scrollbar } from '../../atoms/ScrollBar';
import { Toast } from '../../atoms/Toast';
import { QuitConfirm } from '../../molecules/QuitConfirm';
import { TextInput } from '../../molecules/TextInput';
import { ButtonReset } from '../../atoms/ButtonReset';
import { useChatMessages } from './ChatMessages.hook';
import { ChatControl } from '../ChatControl';
import { InstanceType } from '../../../service/type';
import { intentsLoadingState } from '../../../states';
import { formLoadingState, initialMassageSendingState } from '../../../states/loading/intentsLoadingState';

interface IChatMessages {
  isShowQuitConfirm: boolean;
  hideQuitConfirm: () => void;
}

export const ChatMessages = (props: IChatMessages): JSX.Element => {
  const { text, messages, hideChat, handleReset, handleChangeText, handleSend, lastMessageType } = useChatMessages();
  const { isShowQuitConfirm, hideQuitConfirm } = props;
  const IsIntentsLoading = useRecoilValue(intentsLoadingState);
  const IsFormLoading = useRecoilValue(formLoadingState);
  const initialMassageSending = useRecoilValue(initialMassageSendingState);

  const scrollRef = useRef<HTMLDivElement>(null);
  const endOfItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    endOfItemRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages.length]);

  return (
    <div className={styles.container}>
      <Scrollbar ref={scrollRef}>
        <div className={styles.content}>
          <div className={styles.chatMessages}>
            <div className={styles.toast}>
              <Toast text="AIアバターとの会話を開始しました。" type="info" />
            </div>
            {messages.map((item, index) => (
              <div key={item.created_at + index.toString()}>
                <ChatControl
                  message={item}
                  nextMessage={messages[index + 1]}
                  formId={item.created_at + index.toString()}
                />
              </div>
            ))}
            {IsIntentsLoading && !IsFormLoading && (
              <div className={styles.loader}>
                <p className={styles.loaderText}>
                  入力中
                  <span className={styles.loaderDot}>.</span>
                  <span className={styles.loaderDot}>.</span>
                  <span className={styles.loaderDot}>.</span>
                </p>
              </div>
            )}
            <div ref={endOfItemRef} />
          </div>
        </div>
      </Scrollbar>

      {/* NOTE: 初回レンダリング時にfadeOutのアニメーションが流れるので表示をdelayさせている */}
      <div className={styles.delay}>
        <div className={lastMessageType === InstanceType.end_of_scenario ? styles.fadeIn : styles.fadeOut}>
          <div className={styles.footer}>
            <div className={styles.reset}>
              <p className={styles.txtReset}>チャットを終了しました。</p>
              <ButtonReset label="はじめに戻る" onClick={handleReset} />
            </div>
          </div>
        </div>

        <div
          className={
            lastMessageType === InstanceType.user_input ||
            lastMessageType === InstanceType.bot ||
            lastMessageType === InstanceType.avatar_chats_answer
              ? styles.fadeIn
              : styles.fadeOut
          }
        >
          <div className={styles.footer}>
            <TextInput
              text={text}
              placeholder="質問を入力してください。"
              onChangeCallBack={handleChangeText}
              onSubmit={handleSend}
              disabled={initialMassageSending}
            />
          </div>
        </div>
      </div>

      <QuitConfirm
        filledButtonText="終了"
        hideChat={hideChat}
        hideQuitConfirm={hideQuitConfirm}
        isShow={isShowQuitConfirm}
        outlineButtonText="キャンセル"
        text="チャットを終了しますか？"
      />
    </div>
  );
};
