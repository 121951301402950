import { useRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { formSubmissionState } from '../../../states/message/messageState';
import { IFormInput } from './SubmitGoogleForm.type';
import { useMessageUtil } from '../../../contexts/ChatContext/utils/message.utils';
import { ERROR_MESSAGE, generateThanksMessage } from '../../../consts/setting';
import { TMessage } from '../../../service/type';

const FormFieldNames: { [key in keyof IFormInput]: string } = {
  name: `entry.${process.env.NEXT_PUBLIC_FEATURE_GOOGLE_FORM_NAME_ENTRY || ''}`,
  email: `entry.${process.env.NEXT_PUBLIC_FEATURE_GOOGLE_FORM_EMAIL_ENTRY || ''}`,
  number: `entry.${process.env.NEXT_PUBLIC_FEATURE_GOOGLE_FORM_NUMBER_ENTRY || ''}`,
  content: `entry.${process.env.NEXT_PUBLIC_FEATURE_GOOGLE_FORM_CONTENT_ENTRY || ''}`,
};

// eslint-disable-next-line max-lines-per-function
export const useSubmitGoogleForm = (formId: string) => {
  const { register, handleSubmit } = useForm<IFormInput>({
    defaultValues: {
      name: '',
      email: '',
      number: '',
      content: '',
    },
  });

  const [isSubmitted, setIsSubmitted] = useRecoilState(formSubmissionState(formId));
  const { addMessage } = useMessageUtil();

  const submitData = (formData: IFormInput) => {
    const urlSearchParams = new URLSearchParams();
    Object.keys(FormFieldNames).forEach((key) => {
      const formFieldName = FormFieldNames[key as keyof IFormInput];
      const formValue = formData[key as keyof IFormInput];
      if (formValue) {
        urlSearchParams.append(formFieldName, formValue);
      }
    });

    fetch(`${process.env.NEXT_PUBLIC_OPENAI_ENDPOINT || 'http://localhost:3001'}/api/submitGoogleForm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: urlSearchParams.toString(),
    })
      .then((response) => {
        if (response.ok) {
          setIsSubmitted(true);
          addMessage(generateThanksMessage() as TMessage[]).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
        }
        if (!response.ok) {
          addMessage(ERROR_MESSAGE as TMessage[]).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
          });
          throw new Error('Form submission failed');
        }
        return response.text();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  const onSubmit = handleSubmit((formData: IFormInput) => {
    submitData(formData);
  });

  return { register, onSubmit, isSubmitted };
};
