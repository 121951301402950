import { useCallback } from 'react';
import { ChatLaunchButton } from '../../organisms/ChatLaunchButton';
import { ChatContainer } from '../../organisms/ChatContainer';
import { Close } from '../../../assets/icons';
import styles from './ChatBot.module.scss';

interface IChatBox {
  bottom?: number;
  right?: number;
  isChatOpen: boolean;
}
export const ChatBot = ({ bottom = 0, right = 10, isChatOpen }: IChatBox): JSX.Element => {
  const handleClick = useCallback(() => {
    window?.parent?.postMessage({ msg: 'FINISH_CHAT_BOT' }, '*');
  }, []);

  return (
    <div>
      {!isChatOpen ? (
        <div className={styles.container} style={{ bottom, right }}>
          <button className={styles.svg} onClick={handleClick} type="button">
            <Close />
          </button>
          <ChatLaunchButton />
        </div>
      ) : (
        <div className={styles.container} style={{ right }}>
          <ChatContainer />
        </div>
      )}
    </div>
  );
};
