export const UrlToLink = ({ inputText }: { inputText: string | undefined }): JSX.Element | null => {
  if (!inputText) {
    return null;
  }

  const urlPattern = /(https?:\/\/[^\s]+)/gi;
  const parts: string[] = inputText.split(urlPattern);

  return (
    <>
      {parts.map((part) => {
        if (urlPattern.test(part)) {
          return (
            <a key={part} href={part} target="_blank" rel="noopener noreferrer">
              {part}
            </a>
          );
        }

        return part;
      })}
    </>
  );
};
