import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { InstanceType, TMessage } from '../../../service/type';
import { messageState } from '../../../states';
import { useSwitchAvatarSource } from './hooks/useSwitchAvatarSource';

export const useChatContainer = () => {
  const [isShowQuitConfirm, setIsShowQuitConfirm] = useState<boolean>(false);
  const [source, setSource] = useState('');
  const messages = useRecoilValue(messageState);
  const { avatarSource } = useSwitchAvatarSource();

  const lastMessage: TMessage = useMemo(() => messages[messages.length - 1], [messages]);

  useEffect(() => {
    if (lastMessage?.instance_type === InstanceType.avatar_motion && lastMessage.params.gesture) {
      setSource(lastMessage.params.gesture);
    }
  }, [lastMessage]);

  const showQuitConfirm = () => {
    setIsShowQuitConfirm(true);
  };
  const hideQuitConfirm = () => {
    setIsShowQuitConfirm(false);
  };

  return {
    isShowQuitConfirm,
    showQuitConfirm,
    hideQuitConfirm,
    source,
    avatarSource,
  };
};
