import { memo } from 'react';
import styles from './SubmitGoogleForm.module.scss';
import { useSubmitGoogleForm } from './SubmitGoogleForm.hook';
import { ISubmitGoogleFormProps } from './SubmitGoogleForm.type';

export const SubmitGoogleForm = memo(({ data, formId }: ISubmitGoogleFormProps): JSX.Element => {
  const { register, onSubmit, isSubmitted } = useSubmitGoogleForm(formId);

  return (
    <div className={styles.container}>
      <span>{data?.params.message}</span>
      <span>
        専任の担当者ですとお問い合わせ内容にお答えできる可能性がございます。
        <br />
        担当者からの連絡をご希望の方は
        <a href="https://www.hokende.com/information/privacy" target="_blank" rel="noreferrer">
          個人情報の取扱い
        </a>
        に同意の上、お客さまの情報とお問い合わせ内容をご入力ください。
      </span>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={onSubmit}>
        <div className={styles.googleFormInput}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="name">ニックネーム</label>
          <input {...register('name')} id="name" name="name" disabled={isSubmitted} />
        </div>
        <div className={styles.googleFormInput}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="email">
            メールアドレス（必須）<span>*</span>
          </label>
          <input {...register('email')} id="email" name="email" required type="email" disabled={isSubmitted} />
        </div>
        <div className={styles.googleFormInput}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="number">電話番号</label>
          <input {...register('number')} id="number" name="number" type="number" disabled={isSubmitted} />
        </div>
        <div className={styles.googleFormInput}>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="content">お問合せ内容</label>
          <textarea {...register('content')} id="content" name="content" disabled={isSubmitted} />
        </div>
        <div className={styles.googleFormButton}>
          <button type="submit" disabled={isSubmitted}>
            {isSubmitted ? '送信しました' : '同意して送信する'}
          </button>
        </div>
      </form>
    </div>
  );
});

SubmitGoogleForm.displayName = 'SubmitGoogleForm';
