import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { chatsLoadingState, intentsLoadingState } from '../../../../../states';

export const useSwitchAvatarSource = () => {
  const isIntentsLoading = useRecoilValue(intentsLoadingState);
  const isChatsLoading = useRecoilValue(chatsLoadingState);
  const [avatarSource, setAvatarSource] = useState('Taiki');
  const timerRef = useRef<number>();

  useEffect(() => {
    // デフォルトのAPNGを設定
    setAvatarSource('Taiki');

    if (isIntentsLoading) {
      setAvatarSource('Bow');
      // APNGの再生時間に合わせたタイマーを設定
      timerRef.current = window.setTimeout(() => {
        setAvatarSource('Taiki');
      }, 5000); // APNGの再生時間
    } else if (isChatsLoading) {
      setAvatarSource('Talk');
      timerRef.current = window.setTimeout(() => {
        setAvatarSource('Taiki');
      }, 5000); // APNGの再生時間
    }

    // タイマーをクリア
    return () => {
      if (timerRef.current !== undefined) {
        window.clearTimeout(timerRef.current);
      }
    };
  }, [isIntentsLoading, isChatsLoading]);

  return {
    avatarSource,
  };
};
